import React from "react";
import styled from "styled-components";
import { CardSkeleton } from "../../components/skeleton/Card";

const SGridBody = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 30px;
  overflow: hidden;
  position: relative;
  grid-template-columns: repeat(${({ count }) => (count > 4 ? 4 : count)}, 1fr);
  margin-top: 58px;
  @media only screen and (min-width: 1920px) {
    grid-template-columns: repeat(
      ${({ count }) => (count > 6 ? 6 : count)},
      1fr
    );
  }
`;

export const NewsGridSkeleton = ({ count = 10 }) => {
  return (
    <SGridBody count={count}>
      {Array.from({ length: count }).map((_, index) => (
        <CardSkeleton key={index} />
      ))}
    </SGridBody>
  );
};
