export const API_URLS = {
  SERVER_STATUS: "",
  CREATE_FILE: "files",
  GET_FILES_ROOT: "files/root",
  GET_FILE: "files",
  GET_FILES: "files",
  SEARCH_FILES: "files/search",
  UPDATE_FILE: "files",
  DELETE_FILE: "files",
  GET_FOLDER: "files/folder",
  CREATE_GROUP: "groups",
  EDIT_GROUP: "groups",
  GET_GROUPS: "groups",
  DELETE_GROUP: "groups",
  CREATE_DOCUMENT: "files",
  SEARCH_DOCUMENT: "files",
  GET_USERS: "users",
  GET_MY_PROFILE: "users/me",
  POST_MY_PERMISSIONS: "users/permissions",
  GET_CBRE_USERS: "users",
  GET_SINGLE_USER: "users",
  DELETE_USER: "users",
  ADD_CONTACT: "users/contacts",
  LOGIN: "users/auth/login",
  SET_COOKIES: "users/cookies",
  POST_SUPPORT_MAIL: "users/support/app",
  POST_SHARE_MAIL: "users/support/share",
  GET_DASHBOARD: "files/dashboards",
  CREATE_URL_DASHBOARD: "files/dashboards/get-url",
  GET_ADMIN_PERMISSIONS: "files/admin",
  SIGN_POWERBI: "files/dashboards/sign-powerbi",
  CREATE_SECTOR_DASHBOARD: "files",
  UPDATE_SECTOR_DASHBOARD: "files",
  DELETE_SECTOR_DASHBOARD: "files",
  GET_ONE_SECTOR_DASHBOARD: "files",
  GET_SECTOR_FOLDER: "files/folder",

  GET_TAGS: "tags",
  DELETE_TAG: "tags",
  GET_TAG: "tags",
  UPDATE_TAG: "tags",

  GET_DASHBOARDS: "files",
  GET_SINGLE_DASHBOARD: "files",
  GET_FOLDERS: "files",
  GET_DOCUMENTS: "files",
  GET_ALL_DOCUMENTS: "files/documents",
  GET_BREADCRUMBS: "files/breadcrumbs",
  GET_DASHBOARDS_TREE: "files/dashboards",
  AINEWS: "ai-news",
  CREATE_NEWS: "news",
  GET_NEWS: "news",
  GET_MODULE_CONTENT: "news/module",
  GET_SINGLE_NEWS: "news",
  UPDATE_NEWS: "news",
  DELETE_NEWS: "news",
  DIGITAL_KIT: "digital-kit",
  GET_OPPORTUNITIES: "files/opportunities",
  CREATE_OPPORTUNITIES: "files/opportunities",
  UPDATE_OPPORTUNITIES: "files/opportunities",
  DELETE_OPPORTUNITIES: "files/opportunities",

  FORECASTING: "forecasting",
  GET_FORECASTING_DOCUMENTS: "forecasting",
  UPDATE_FORECASTING_DOCUMENTS: "forecasting",

  GET_NOTIFICATIONS: "notifications",
  GET_MY_NOTIFICATIONS: "notifications/my",
  GET_NOTIFICATION: "notifications",
  CREATE_NOTIFICATION: "notifications",
  UPDATE_NOTIFICATION: "notifications",
  DELETE_NOTIFICATION: "notifications",
  MARK_AS_READ_NOTIFICATION: "notifications/read",
  CREATE_NEWSLETTER: "notifications/newsletter",

  GET_EVENTS: "events",
  GET_EVENT: "events",
  GET_EVENT_ICS: "events/ics/single",
  GET_EVENT_MULTIPLE_ICS: "events/ics/multiple",

  GET_DATA_LAKE_SECTORS: "forecasting/data-lake/sectors",
  GET_DATA_LAKE_SCENARIOS: "forecasting/data-lake/scenarios",
  GET_DATA_LAKE_GROUPS: "forecasting/data-lake/groups",
  GET_DATA_LAKE_LOCATION_LAYERS: "forecasting/data-lake/location-layers",
  GET_DATA_LAKE_LOCATION_LAYERS_ALL:
    "forecasting/data-lake/all-location-layers",

  GET_FAVOURITE_QUERIES: "/forecasting/favorite-queries",
  SAVE_FAVOURITE_QUERY: "/forecasting/favorite-queries",
  GET_FAVOURITE_QUERY: "/forecasting/favorite-queries",
  DELETE_FAVOURITE_QUERY: "/forecasting/favorite-queries",

  GET_DATA_LAKE_VALUES: "forecasting/data-lake/values",
  GET_MEASUREMENTS: "forecasting/data-lake/measurements",
  GET_FREQUENCIES: "forecasting/data-lake/frequencies",
  GET_TIME_SPAN: "forecasting/data-lake/time-span",
  GENERATE_EXCEL: "v2/forecasting/data-lake/excel",

  GET_DATA_LAKE_VALUES_TABLE: "v2/forecasting/data-lake/values",
  GET_TABLE_ROW_COUNT: "v2/forecasting/data-lake/values-size",

  GET_MINI_TABLE_KPI_VALUES: "forecasting/data-lake/values",

  GET_LOCATIONS_TREE: "forecasting/locations",

  CREATE_FA_PERMISSIONS: "groups",
  GET_FA_PERMISSIONS: "groups",
  EDIT_FA_PERMISSIONS: "groups",
  DELETE_FA_PERMISSIONS: "groups",

  GET_LATERAL_MENU: "files/lateral-menu",
  CREATE_LATERAL_MENU: "files/lateral-menu",
  EDIT_LATERAL_MENU: "files/lateral-menu",
  DELETE_LATERAL_MENU: "files/lateral-menu",
  CREATE_TABLEAU_DASHBOARD: "files",
  EDIT_TABLEAU_DASHBOARD: "files",
  GET_TABLEAU_STRUCTURE_MENU: "files/folder",
  DELETE_TABLEAU_DASHBOARD: "files",

  CREATE_PERMISSION: "groups",
  GET_PERMISSIONS: "groups",
  UPDATE_PERMISSION: "groups",
  DELETE_PERMISSION: "groups",

  UPDATE_MODULE: "files/module",
  CREATE_ROOT_MODULE: "files/root-module",
  UPDATE_ROOT_MODULE: "files/root-module",
  DELETE_ROOT_MODULE: "files",

  GET_ADMIN_SHARED_FOLDERS: "admin/shared-folders",
  CREATE_ADMIN_SHARED_FOLDER: "admin/shared-folders",
  UPDATE_ADMIN_SHARED_FOLDER: "admin/shared-folders",
  DELETE_ADMIN_SHARED_FOLDER: "admin/shared-folders",

  GET_SHARED_FOLDERS: "shared-folders",
  CREATE_SHARED_FILE: "shared-folders/files",
  UPDATE_SHARED_FILE: "shared-folders/files",
  DELETE_SHARED_FILE: "shared-folders/files",

  GET_SHARED_FOLDER: "files/folder",

  CREATE_LEGAL_NOTICE_FDL: "constants",
  GET_LEGAL_NOTICE_FDL: "constants",
  UPDATE_LEGAL_NOTICE_FDL: "constants",
  DELETE_LEGAL_NOTICE_FDL: "constants",

  CREATE_SECTION: "about-us/sections",
  GET_SECTIONS: "about-us/sections",
  DELETE_SECTION: "about-us/sections",
  GET_SECTION: "about-us/sections",
  UPDATE_SECTION: "about-us/sections",

  CREATE_CARD: "about-us/cards",
  GET_CARDS: "about-us/cards",
  DELETE_CARD: "about-us/cards",
  GET_CARD: "about-us/cards",
  UPDATE_CARD: "about-us/cards",

  GET_SECTIONS_WITH_CARDS: "about-us",

  CREATE_TYPE_GROUP: "tags-groups",
  GET_TYPE_GROUPS: "tags-groups",
  DELETE_TYPE_GROUP: "tags-groups",
  GET_TYPE_GROUP: "tags-groups",
  UPDATE_TYPE_GROUP: "tags-groups",

  CREATE_TAG: "tags",

  GET_ROOT_MENUS: "files/root-menus",
  GET_BUILD_IN_MENUS: "files/build-in-menus",
  GET_CUSTOM_MENUS: "files/custom-menus",
  GET_MODULE_MENUS: "files/module-menus",
  GET_DASHBOARD_MENUS: "files/dashboard-menus",

  GET_FAVORITES: "favorites",
  GET_OBJECT: "files/object",

  PEOPLE: "people-mod",
};
